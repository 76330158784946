<template>
  <div
    variant="plain"
    @click="handleClick"
    class="d-flex flex-column align-center justify-center container-browser-base-tile"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <v-container
      class="d-flex ma-0 pa-0 container-browser-base-tile-content"
      :style="{ width: containerWidth, height: containerHeight }"
    >
      <slot name="preview">[placeholder]</slot>

      <v-container
        class="d-flex pa-0 container-browser-base-tile-action-icons"
        :class="{ visible: hover }"
      >
        <v-btn
          v-if="canBeStarred"
          variant="plain"
          density="comfortable"
          aria-label="Like icon"
          tooltip="Like picture"
          aria-hidden="false"
          :color="props.starred ? 'error' : 'primary'"
          @click.stop="fireStarEvent(item)"
          :icon="props.starred ? 'mdi-heart' : 'mdi-heart-outline'"
        >
        </v-btn>
        <ConfettiExplosion
          v-if="visible"
          :force="0.3"
          :colors="[
            'rgba(255, 105, 180, 1)', // Hot Pink
            'rgba(255, 165, 0, 1)', // Orange
            'rgba(255, 255, 0, 1)', // Yellow
            'rgba(0, 255, 127, 1)', // Spring Green
            'rgba(0, 191, 255, 1)', // Deep Sky Blue
          ]"
          :duration="4000"
          :particleSize="2"
          :particleCount="50"
          :stageHeight="600"
          :stageWidth="300"
        />
        <v-btn
          v-if="canBeDeleted"
          density="comfortable"
          variant="plain"
          aria-label="Delete icon"
          tooltip="Delete"
          aria-hidden="false"
          color="primary"
          @click.stop="fireDeleteEvent(item)"
          icon="mdi-delete"
        >
        </v-btn>
        <v-btn
          v-if="canBeDownloaded"
          density="comfortable"
          variant="plain"
          aria-label="Download icon"
          tooltip="Download"
          aria-hidden="false"
          color="primary"
          @click.stop="fireDownloadEvent(item)"
          icon="mdi-download"
        >
        </v-btn>
      </v-container>

      <v-icon
        v-if="canBeSelected && selected"
        aria-label="Selection icon"
        aria-hidden="false"
        size="large"
        color="black"
        class="container-browser-base-tile-selection-icon"
        icon="mdi-checkbox-marked-outline"
        @click.stop="fireSelectEvent(item)"
      ></v-icon>
      <v-icon
        v-else-if="canBeSelected && hover && !selected"
        aria-label="Selection icon"
        aria-hidden="false"
        size="large"
        color="black"
        class="container-browser-base-tile-selection-icon"
        icon="mdi-checkbox-blank-outline"
        @click.stop="fireDeSelectEvent(item)"
      ></v-icon>
    </v-container>

    <v-container
      class="d-flex align-center justify-center text-caption base-tile-name"
      :style="filenameComputedStyle"
    >
      <slot name="name">
        <v-icon
          v-if="props.starred"
          aria-label="Starred icon"
          aria-hidden="false"
          class="mr-1"
          color="error"
          size="small"
          icon="mdi-heart"
        ></v-icon>
        <span>{{ truncatedName }}</span>
      </slot>
    </v-container>
  </div>
</template>

<script setup>
import {
  defineEmits,
  onBeforeUnmount,
  onMounted,
  computed,
  ref,
  nextTick,
  defineProps,
} from "vue";
import ConfettiExplosion from "vue-confetti-explosion";
import { useDisplay, useTheme } from "vuetify";

const { name } = useDisplay();

const currentBreakpoint = computed(() => {
  return name.value;
});

// Local reactive state
const containerWidth = ref("200px");
const containerHeight = ref("200px");

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  useDynamicSizing: {
    type: Boolean,
    required: false,
    default: false,
  },
  starred: {
    type: Boolean,
    required: false,
  },
  canBeStarred: { type: Boolean, required: false, default: false },
  canBeDeleted: { type: Boolean, required: false, default: false },
  canBeDownloaded: { type: Boolean, required: false, default: false },
  selected: { type: Boolean, required: false, default: false },
  canBeSelected: { type: Boolean, required: false, default: false },
  showColorAccent: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const theme = useTheme();

const filenameComputedStyle = computed(() => {
  return {
    color: theme.current.value.colors.onBackground,
  };
});

const truncatedName = computed(() => {
  const maxLength = 26; // Adjust the max length as needed
  return props.item.name.length > maxLength
    ? props.item.name.substring(0, maxLength) + "..."
    : props.item.name;
});

const updateContainerSize = () => {
  if (!props.useDynamicSizing) {
    return;
  }

  const size = currentBreakpoint.value;

  if (size == "sm") {
    containerWidth.value = "150px";
    containerHeight.value = "150px";
  } else if (size == "md") {
    containerWidth.value = "180px";
    containerHeight.value = "180px";
  } else if (size == "lg") {
    containerWidth.value = "250px";
    containerHeight.value = "250px";
  } else if (size == "xl") {
    containerWidth.value = "300px";
    containerHeight.value = "300px";
  } else if (size == "xxl") {
    containerWidth.value = "350px";
    containerHeight.value = "350px";
  } else {
    containerWidth.value = "400px";
    containerHeight.value = "400px";
  }

  // eslint-disable-next-line prettier/prettier
  console.debug("New container size:", containerWidth.value, containerHeight.value, size);
};

// Handle window resize
function handleResize() {
  updateContainerSize();
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  updateContainerSize(); // Initial call to set the size
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const emit = defineEmits([
  "click",
  "star",
  "delete",
  "download",
  "select",
  "deselect",
]);

const handleClick = (event) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("BaseTile clicked", props.item);
    console.debug("Ctrl key pressed:", event.ctrlKey);
    console.debug("Shift key pressed:", event.shiftKey);
  }

  emit("click", event, props.item);
};

const hover = ref(false);
const visible = ref(false);
const isTouchDevice = ref(false);
let hoverTimeout = null;

const handleTouchStart = () => {
  isTouchDevice.value = true;
  hover.value = true;

  // Cancella il timer precedente (se esiste)
  if (hoverTimeout) {
    clearTimeout(hoverTimeout);
  }

  // Imposta il timer per disattivare `hover` dopo 10 secondi
  hoverTimeout = setTimeout(() => {
    hover.value = false;
  }, 5000); // 10 secondi
};

const handleTouchEnd = () => {
  // Manteniamo `hover` attivo finché il timer non scade
  // hover.value = false;
};

const fireStarEvent = async (item) => {
  emit("star", item);

  if (!props.starred) {
    visible.value = false;
    await nextTick();
    visible.value = true;
  }
};

const fireDeleteEvent = async (item) => {
  emit("delete", item);
};

const fireDownloadEvent = async (item) => {
  emit("download", item);
};

const fireSelectEvent = async (item) => {
  emit("select", item);
};

const fireDeSelectEvent = async (item) => {
  emit("deselect", item);
};
</script>

<style>
.container-browser-base-tile {
  opacity: 1;
  overflow: visible;
}

.container-browser-base-tile-content {
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
}

.base-tile-name {
  background-color: transparent !important;
}

.container-browser-base-tile-selection-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  backdrop-filter: blur(16px);
}

.container-browser-base-tile-action-icons {
  position: absolute;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(16px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(168, 168, 168, 0.3);
  width: auto;
  opacity: 0;
  transition: opacity 0.3s;
}

.container-browser-base-tile-action-icons.visible {
  opacity: 1;
}
</style>
