<template>
  <v-main
    id="main-app-container"
    class="accent"
    :style="{ backgroundImage: showBackgroundImage ? backgroundImage : '' }"
  >
    <v-app-bar
      rounded="lg"
      elevation="4"
      style="position: absolute; top: 0; left: 0; right: 0; z-index: 1000"
      class="app-bar-backdrop-filter ma-2"
    >
      <template v-slot:prepend>
        <v-container class="d-flex align-center justify-center">
          <AppLogo alwaysShowText></AppLogo>

          <v-toolbar-items class="hide-on-small-screens">
            <v-btn
              variant="plain"
              :to="{ path: '/', hash: '#landing-features' }"
            >
              {{ $t("full-screen-page.features") }}
            </v-btn>
            <v-btn
              variant="plain"
              :to="{ path: '/', hash: '#landing-contact' }"
            >
              {{ $t("full-screen-page.contacts") }}
            </v-btn>
            <v-btn variant="plain" :to="{ path: '/', hash: '#landing-about' }">
              {{ $t("full-screen-page.about") }}
            </v-btn>
          </v-toolbar-items>
        </v-container>
      </template>

      <template v-slot:append>
        <v-toolbar-items>
          <v-menu width="200px" rounded>
            <template v-slot:activator="{ props }">
              <v-btn variant="plain" v-bind="props">
                <template v-slot:prepend>
                  <v-img
                    src="https://flagicons.lipis.dev/flags/4x3/gb.svg"
                    width="24"
                    height="16"
                    class="mr-2"
                    v-if="locale === 'en'"
                  ></v-img>
                  <v-img
                    src="https://flagicons.lipis.dev/flags/4x3/vn.svg"
                    width="24"
                    height="16"
                    class="mr-2"
                    v-if="locale === 'vi'"
                  ></v-img>
                  <v-img
                    src="https://flagicons.lipis.dev/flags/4x3/fr.svg"
                    width="24"
                    height="16"
                    class="mr-2"
                    v-if="locale === 'fr'"
                  ></v-img>
                </template>
                <span class="d-sm-none">{{ locale }}</span
                ><span class="d-none d-sm-block">{{ currentLanguage }}</span>
              </v-btn>
            </template>
            <LocaleSelector></LocaleSelector>
          </v-menu>
          <v-btn prepend-icon="mdi-login" variant="plain" to="/login">
            {{ $t("full-screen-page.login") }}
          </v-btn>
        </v-toolbar-items>
      </template>
    </v-app-bar>

    <router-view :key="$route.fullPath" />

    <AppFooter app class="pa-2" :imageCredits="copyright"> </AppFooter>
  </v-main>
</template>

<script setup>
import AppLogo from "@/components/AppLogo.vue";
import AppFooter from "@/components/AppFooter.vue";
import LocaleSelector from "@/i18n/LocaleSelector.vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { ref, onMounted, nextTick, computed } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

const router = useRouter();
const { locale, t } = useI18n();

// Local reactive state
const showBackgroundImage = ref(false);
const backgroundImage = ref("");
const copyright = ref("");
const copyrightLink = ref("");

// Computed property for the current language name
const currentLanguage = computed(() => t(`locale-selector.${locale.value}`));

onBeforeRouteUpdate((to, from, next) => {
  console.log("Route is updating from", from.name, "to", to.name);

  if (to.name === "login" || to.name === "collectionviewerlanding") {
    showBackgroundImage.value = true;
    fetchBackgroundImage();
  } else {
    showBackgroundImage.value = false;
  }

  next();
});

// eslint-disable-next-line no-unused-vars
function logout() {
  nextTick(() => {
    router.push({ name: "home" });
  });
}

onMounted(() => {
  console.log("Full screen page has been mounted");

  if (
    router.currentRoute.value.name === "login" ||
    router.currentRoute.value.name === "collectionviewerlanding"
  ) {
    showBackgroundImage.value = true;
    fetchBackgroundImage();
  } else {
    showBackgroundImage.value = false;
  }
});

// https://github.com/TimothyYe/bing-wallpaper
const fetchBackgroundImage = async () => {
  try {
    const resolutions = [
      { width: 3840, height: 2160, res: "UHD" },
      { width: 1920, height: 1200, res: "1920x1200" },
      { width: 1920, height: 1080, res: "1920x1080" },
      { width: 1366, height: 768, res: "1366x768" },
      { width: 1280, height: 768, res: "1280x768" },
      { width: 1024, height: 768, res: "1024x768" },
      { width: 800, height: 600, res: "800x600" },
      { width: 800, height: 480, res: "800x480" },
      { width: 768, height: 1280, res: "768x1280" },
      { width: 720, height: 1280, res: "720x1280" },
      { width: 640, height: 480, res: "640x480" },
      { width: 480, height: 800, res: "480x800" },
      { width: 400, height: 240, res: "400x240" },
      { width: 320, height: 240, res: "320x240" },
      { width: 240, height: 320, res: "240x320" },
    ];
    const screenWidth = window.outerWidth;
    const screenHeight = window.outerHeight;
    let selectedResolution = "UHD";
    for (const resolution of resolutions) {
      if (
        (screenWidth >= resolution.width &&
          screenHeight >= resolution.height) ||
        (screenWidth >= resolution.height && screenHeight >= resolution.width)
      ) {
        selectedResolution = resolution.res;
        break;
      }
    }
    const marketMapping = {
      "en-US": "en-US",
      "en-GB": "en-GB",
      "en-AU": "en-AU",
      "en-NZ": "en-NZ",
      "en-CA": "en-CA",
      de: "de-DE",
      ja: "ja-JP",
      zh: "zh-CN",
    };

    let selectedMarket = "de-DE";
    const userLanguage = navigator.language || navigator.languages[0];

    let marketFound = false;
    for (const key in marketMapping) {
      if (userLanguage.startsWith(key)) {
        selectedMarket = marketMapping[key];
        marketFound = true;
        break;
      }
    }

    if (!marketFound && process.env.NODE_ENV === "development") {
      console.warn(`Locale ${userLanguage} can't be mapped to a market.`);
    }

    if (process.env.NODE_ENV === "development") {
      console.log("Selected resolution:", selectedResolution);
      console.log("Selected market:", selectedMarket);
    }

    const response = await axios.get(
      `https://bing.biturl.top/?resolution=${selectedResolution}&format=json&index=0&mkt=${selectedMarket}`,
    );

    backgroundImage.value = `url(${response.data.url})`;
    copyright.value = response.data.copyright;
    copyrightLink.value = response.data.copyright_link;

    console.log("Fetched background image", response.data);
  } catch (error) {
    backgroundImage.value = `url(https://bing.biturl.top/?resolution=1920&format=image&index=0&mkt=en-US)`;
    console.error("Failed to fetch background image", error);
  }
};
</script>

<style scoped>
.app-bar-backdrop-filter {
  backdrop-filter: blur(16px);
  box-sizing: border-box !important;
  width: calc(100% - 16px) !important;
}
.accent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0;
  height: 100vh;
}
.hide-on-small-screens {
  display: none;
}

@media (min-width: 600px) {
  .hide-on-small-screens {
    display: flex;
  }
}
</style>
