<template>
  <v-container
    class="d-flex no-select align-center justify-start pa-0"
    style="max-width: 150px"
    @click="$router.push('/')"
  >
    <v-img src="@/assets/jellypic-symbol.svg" width="32" height="32" />
    <v-img
      src="@/assets/jellypic-text.svg"
      width="100"
      height="32"
      :class="['ml-2', { 'd-none d-sm-block': !alwaysShowText }]"
    />
  </v-container>
</template>

<script setup>
import { onMounted } from "vue";
import { ref } from "vue";

// props
const props = defineProps({
  alwaysShowText: {
    type: Boolean,
    default: false,
  },
});

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped></style>
