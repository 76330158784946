<script setup>
import { defineExpose, onMounted } from "vue";
import { useShepherd } from "vue-shepherd";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const tour = useShepherd({
  useModalOverlay: true,
});

onMounted(() => {
  tour.addStep({
    title: "🙋‍♀️ <b>Welcome!</b>&nbsp;",
    closeButton: true,
    text: t("collection-viewer-tour.welcome-message"),
    arrow: false,
    buttons: [
      {
        text: t("collection-viewer-tour.next"),
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser", on: "top" },
    closeButton: true,
    title: "📸&nbsp;<b>Photos</b>&nbsp;view",
    text: t("collection-viewer-tour.album-view-description-message"),
    arrow: false,
    buttons: [
      {
        text: t("collection-viewer-tour.next"),
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser-head-actions-starred", on: "top" },
    title: "❤️&nbsp;View only&nbsp;<b>liked</b>&nbsp;photos",
    text: t("collection-viewer-tour.filter-liked-pictures-message"),
    arrow: true,
    buttons: [
      {
        text: t("collection-viewer-tour.next"),
        action: tour.next,
      },
      {
        text: t("collection-viewer-tour.back"),
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser-head-actions-sort", on: "bottom" },
    title: "🔍&nbsp;Sort photos",
    text: t("collection-viewer-tour.sort-command-desription-message"),
    arrow: true,
    buttons: [
      {
        text: t("collection-viewer-tour.next"),
        action: tour.next,
      },
      {
        text: t("collection-viewer-tour.back"),
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    attachTo: {
      element: "#container-browser-head-actions-view-mode",
      on: "left",
    },
    title: "🖼️&nbsp;Change&nbsp;<b>view mode</b>",
    text: t("collection-viewer-tour.toggle-between-grid-and-gallery-view"),
    arrow: true,
    buttons: [
      {
        text: t("collection-viewer-tour.next"),
        action: tour.next,
      },
      {
        text: t("collection-viewer-tour.back"),
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    title: "🎉&nbsp;<b>That's it!</b> You're all set!",
    text: t("collection-viewer-tour.all-set-lets-go-message"),
    arrow: true,
    buttons: [
      {
        text: "Let's go",
        action: tour.complete,
      },
    ],
  });
});

// Define the startTour function
function startTour() {
  tour.start();
}

// Expose the function
defineExpose({
  startTour,
});
</script>

<template>
  <span></span>
</template>

<style>
.shepherd-arrow {
  border: none;
}

.shepherd-arrow:before {
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
</style>
