<template>
  <div v-if="viewerMode" class="tag-box">
    <v-chip
      v-for="(item, i) in selectedTags"
      :color="item.color"
      :text="item.name"
      variant="flat"
      size="small"
      class="tag"
      readonly
      :key="i"
    ></v-chip>
  </div>
  <v-combobox
    v-else
    @update:focused="setFileTagsOnFocusOut"
    v-model:search="search"
    v-model="selectedTags"
    :readonly="isUpdating || updateDisabled"
    :loading="isUpdating"
    :return-object="true"
    :hide-no-data="false"
    item-color="color"
    item-title="name"
    item-value="id"
    :items="tags"
    variant="plain"
    hide-selected
    multiple
    label="Tags"
    hint="Start typing to search for tags"
    :menu-props="{ maxWidth: '250', maxHeight: '600' }"
    :menu="search.length > 0"
  >
    <template v-slot:selection="{ item }">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-chip
            @click:close="removeSelection(item.raw.id)"
            :closable="isHovering && !isUpdating && !updateDisabled"
            :readonly="isUpdating || updateDisabled"
            :color="item.raw.color"
            :text="item.raw.name"
            v-bind="props"
            variant="flat"
            size="small"
          ></v-chip>
        </template>
      </v-hover>
    </template>
    <template v-slot:item="{ item, props }">
      <v-chip
        class="ma-1"
        @click="props.onClick"
        :color="item.raw.color"
        :text="item.raw.name"
        variant="flat"
        size="small"
      ></v-chip>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{ t("tagSelector.no-matches") }} <strong>{{ search }}</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script setup>
import { useTagStore } from "@/stores/tag.store";
import { storeToRefs } from "pinia";
import { ref, watch, defineModel, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const tagStore = useTagStore();
const { updateDisabled } = storeToRefs(tagStore);

const fileId = defineModel("fileId", {
  type: Number,
  required: true,
});

const viewerMode = defineModel("viewerMode", {
  type: Boolean,
  default: false,
});

const tags = defineModel("tags", {
  type: Array,
  required: true,
});

const fileTags = defineModel("fileTags", {
  type: Array,
  required: true,
});

const selectedTags = ref([]);
const search = ref("");
const isUpdating = ref(false);

const getSelectedTags = () => {
  const tagIds = fileTags.value
    .filter((ft) => ft.file == fileId.value)
    .map((ft) => ft.tag);

  return tags.value.filter((t) => tagIds.includes(t.id));
};

const removeSelection = (id) => {
  selectedTags.value = selectedTags.value.filter((t) => t.id != id);
};

const setFileTagsOnFocusOut = async (isFocused) => {
  if (!isFocused && !viewerMode.value && !isUpdating.value) {
    try {
      isUpdating.value = true;
      await tagStore.setTags(fileId.value, selectedTags.value);
    } catch {
      // handle error here
    } finally {
      isUpdating.value = false;
    }
  }
};

watch(fileId, () => {
  selectedTags.value = getSelectedTags();
});

watch(tags, () => {
  selectedTags.value = getSelectedTags();
});

watch(fileTags, () => {
  selectedTags.value = getSelectedTags();
});

onMounted(() => {
  selectedTags.value = getSelectedTags();
});
</script>

<style scoped>
.tag-box {
  max-width: 200px;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin: 2px;
  cursor: default;
}
</style>
