import { defineStore } from "pinia";
import {
  addTag,
  deleteTag,
  getFileTags,
  getTags,
  updateTag,
  setTags,
  addTagToFiles,
  removeTagFromFiles,
} from "@/api/tag.client";

export const useTagStore = defineStore(
  // The store ID
  "tag",
  // The store definition
  {
    state: () => ({
      tags: [],
      fileTags: [],
      currentTag: {},
      updateDisabled: false,
    }),

    getters: {
      // Refer to `this.tags` instead of destructuring (state) => state.tags
      getTags() {
        return this.tags;
      },
      getCurrentTags() {
        return this.currentTag;
      },
      getFileTags() {
        return this.fileTags;
      },
    },

    actions: {
      async loadTags() {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("LoadTags");
          }
          const result = await getTags();
          this.tags = result.data;
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("LoadTags: ", exception);
          }
        }
      },

      async loadFileTags(fileIds) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("LoadFileTags");
          }
          if (fileIds.length > 0) {
            const result = await getFileTags(fileIds);
            this.fileTags = result.data;
          } else {
            this.fileTags = [];
          }
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("LoadFileTags: ", exception);
          }
        }
      },

      async addTag(tag) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("AddTag");
          }
          const result = await addTag(tag);
          // Prepend new tag
          this.tags = [result.data, ...this.tags];
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("AddTag: ", exception);
          }
          throw exception;
        }
      },

      async updateTag(tag) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("UpdateTag");
          }
          await updateTag(tag);
          const existingTagIndex = this.tags.findIndex((i) => i.id === tag.id);
          if (existingTagIndex !== -1) {
            const updatedTags = [...this.tags];
            updatedTags[existingTagIndex] = tag;
            this.tags = updatedTags;
          }
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("UpdateTag: ", exception);
          }
          throw exception;
        }
      },

      async deleteTag(tagId) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("DeleteTag");
          }
          await deleteTag(tagId);
          this.tags = this.tags.filter((t) => t.id !== tagId);
          this.fileTags = this.fileTags.filter((ft) => ft.tag !== tagId);
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("DeleteTag: ", exception);
          }
          throw exception;
        }
      },

      async setTags(fileId, tags) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("SetTags");
          }
          const result = await setTags(fileId, tags);
          const tagIds = result.data;
          const fileTags = this.fileTags.filter((ft) => ft.file !== fileId);

          const newFileTags = tagIds.map((tagId) => ({
            file: fileId,
            tag: tagId,
          }));

          this.fileTags = fileTags.concat(newFileTags);
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("SetTags: ", exception);
          }
          throw exception;
        }
      },

      async addTagToFiles(tagId, fileIds) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("AddTagToFiles");
          }
          const result = await addTagToFiles(tagId, fileIds);
          const updatedFileTags = result.data;
          const fileTags = this.fileTags.filter(
            (ft) => !fileIds.includes(ft.file),
          );
          this.fileTags = fileTags.concat(updatedFileTags);
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("AddTagToFiles: ", exception);
          }
          throw exception;
        }
      },

      async removeTagFromFiles(tagId, fileIds) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("RemoveTagFromFiles");
          }
          const result = await removeTagFromFiles(tagId, fileIds);
          const updatedFileTags = result.data;
          const fileTags = this.fileTags.filter(
            (ft) => !fileIds.includes(ft.file),
          );
          this.fileTags = fileTags.concat(updatedFileTags);
        } catch (exception) {
          if (process.env.NODE_ENV === "development") {
            console.error("RemoveTagFromFiles: ", exception);
          }
          throw exception;
        }
      },
    },
  },
);
