import { defineStore } from "pinia";
import { decodeJwt } from "jose";
import { refreshAsync, signInAsync } from "@/api/auth.client";

const localStorageKey = "auth-user";

function decodeJwtToken(token) {
  const decodedToken = decodeJwt(token, {
    complete: true,
    header: true,
  });
  if (process.env.NODE_ENV === "development") {
    console.log("Decoded token: ", decodedToken);
  }
  return decodedToken;
}

export const useAuthStore = defineStore(
  // 1) Store ID
  "auth",
  // 2) Options object
  {
    state: () => ({
      user: {},
      refreshTokenTimeout: null,
    }),

    actions: {
      signOut() {
        this.stopRefreshTokenTimer();
        this.user = {};
        localStorage.removeItem(localStorageKey);
      },
      async signInAsync(exchangeCodeModel) {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("SignIn");
          }
          const result = await signInAsync(exchangeCodeModel);
          const { token, refreshToken } = result.data;
          const decodedToken = decodeJwtToken(token);

          this.user = {
            token,
            refreshToken,
            email: decodedToken.email,
            name: decodedToken.name,
            picture: decodedToken.picture,
          };
          localStorage.setItem(localStorageKey, JSON.stringify(this.user));
          this.startRefreshTokenTimer();
        } catch (exception) {
          this.signOut();
          if (process.env.NODE_ENV === "development") {
            console.error(exception);
          }
          throw exception;
        }
      },
      async refreshTokenAsync() {
        try {
          if (process.env.NODE_ENV === "development") {
            console.log("RefreshToken");
          }
          const result = await refreshAsync({
            refreshToken: this.user.refreshToken,
          });
          const { token, refreshToken } = result.data;
          const decodedToken = decodeJwtToken(token);

          this.user = {
            token,
            refreshToken,
            email: decodedToken.email,
            name: decodedToken.name ?? this.user.name,
            picture: decodedToken.picture ?? this.user.picture,
          };
          localStorage.setItem(localStorageKey, JSON.stringify(this.user));
          this.startRefreshTokenTimer();
        } catch (exception) {
          this.signOut();
          if (process.env.NODE_ENV === "development") {
            console.error(exception);
          }
          throw exception;
        }
      },
      startRefreshTokenTimer() {
        const decodedToken = decodeJwt(this.user.token, {
          complete: true,
          header: true,
        });
        // set a timeout to refresh the token 30s before it expires
        const expires = new Date(decodedToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 30_000;
        this.refreshTokenTimeout = setTimeout(this.refreshTokenAsync, timeout);
        if (process.env.NODE_ENV === "development") {
          console.debug("Refresh token in: ", timeout);
          console.debug("Refresh token at: ", expires);
        }
      },
      stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
      },
      loadDataFromLocalStorage() {
        const data = localStorage.getItem(localStorageKey);
        if (data) {
          const user = JSON.parse(data);
          if (user?.token && user?.refreshToken) {
            this.user = user;
          }
        }
      },
    },

    getters: {
      token: (state) => state.user?.token,
      userName: (state) => state.user?.name,
      userEmail: (state) => state.user?.email,
      userPicture: (state) => state.user?.picture,
      hasToken: (state) => !!state.user?.token,
      hasRefreshToken: (state) => !!state.user?.refreshToken,
    },
  },
);
