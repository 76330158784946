<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <v-container
      class="pb-0"
      :class="{
        'login-card-dark': theme.global.name.value === 'dark',
        'login-card-light': theme.global.name.value === 'light',
      }"
    >
      <template v-if="containerShareFound">
        <!-- Share details row -->
        <v-row dense>
          <v-col cols="12">
            <v-container class="d-flex flex-column align-center justify-center">
              <v-avatar :image="containerOwnerAvatar" size="80"></v-avatar>
              <p class="ma-4 text-center">
                {{ containerShare.name }}
              </p>
              <p class="text-center text-caption">
                {{ containerShare.description }}
              </p>
            </v-container>
          </v-col>
        </v-row>

        <!-- A pin is requested to access -->
        <v-row dense v-if="showPin">
          <v-col
            cols="12"
            class="d-flex alight-center justify-center flex-column"
          >
            <p class="text-center">
              {{ $t("collection-viewer-landing.pin-request-message") }}
            </p>
            <v-otp-input
              v-model="otpInput"
              :length="8"
              :autofocus="true"
              variant="solo-filled"
            ></v-otp-input>
          </v-col>
        </v-row>

        <!-- Login row -->
        <v-row dense v-if="showLogin && !hasErrors">
          <v-col
            cols="12"
            class="d-flex align-center flex-column justify-center"
          >
            <template v-if="authStore.hasToken">
              <!-- Show a simple login button the user already has a token -->
              <v-btn rounded="xl" elevation="4" @click="googleLogin">
                <template v-slot:prepend>
                  <v-icon>mdi-google</v-icon>
                </template>
                {{ $t("collection-viewer-landing.continueWithGoogle") }}</v-btn
              >
              <p class="text-caption my-2">
                <a
                  href="https://www.google.com/recaptcha/about/"
                  target="_blank"
                  style="text-decoration: none"
                  >{{
                    $t("collection-viewer-landing.protected-by-recaptcha")
                  }}</a
                >
              </p>
            </template>
            <template v-else>
              <!-- Here we need to identify the user -->
              <GoogleLogin
                :callback="loginCallback"
                :error="loginError"
                popup-type="CODE"
              >
                <v-btn rounded="xl" elevation="4">
                  <template v-slot:prepend>
                    <v-icon>mdi-google</v-icon>
                  </template>
                  $t('collection-viewer-landing.login-with-google')e</v-btn
                >
              </GoogleLogin>
              &nbsp;
              <v-btn
                rounded="xl"
                elevation="4"
                @click="anonumousLogin"
                v-if="containerShare.allowAnonymousAccess"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-incognito</v-icon>
                </template>
                $t('collection-viewer-landing.view-anonymously')</v-btn
              >
            </template>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="d-flex align-center justify-center">
            $t('collection-viewer-landing.login-error-message')
          </v-col>
        </v-row>

        <!-- Countdown row -->
        <v-row dense v-if="showCountdown">
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              {{ $t("collection-viewer-landing.countdown-message") }}
            </p>
            <Countdown
              mainColor="silver"
              secondFlipColor="silver"
              :deadline="getDeadline()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="!loading && !containerShareFound">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <v-empty-state
              :action-text="t('collection-viewer-landing.go-back-to-home')"
              @click:action="goBackToHomepage"
              image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
              :text="
                t('collection-viewer-landing.share-does-not-exists-message')
              "
              :title="t('collection-viewer-landing.no-share-to-show-here')"
            ></v-empty-state>
          </v-col>
        </v-row>
      </template>

      <!-- Loading row -->
      <template v-if="loading">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              {{ $t("collection-viewer-landing.loading-share-message") }}
            </p>
            <v-progress-circular
              class="my-8"
              :size="70"
              :width="7"
              color="green"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </template>

      <!-- Footer row -->
      <v-row dense>
        <v-col cols="12" class="d-flex flex-column">
          <AppLogo class="ma-4"></AppLogo>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup>
import { getCurrentInstance, nextTick, ref, onMounted } from "vue";
// https://github.com/coskuncay/vue3-flip-countdown
import { Countdown } from "vue3-flip-countdown";
import { getShare } from "@/api/share.client";
import { validateReCaptcha } from "@/api/auth.client";
import {
  getContainerOwner,
  getContainerOwnerAvatar,
} from "@/api/container.client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/stores/auth.store";
import moment from "moment";
import AppLogo from "@/components/AppLogo.vue";
import { useReCaptcha } from "vue-recaptcha-v3";

const theme = useTheme();
// eslint-disable-next-line no-unused-vars
const { t } = useI18n();
const route = useRoute();
const authStore = useAuthStore();

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const { executeRecaptcha } = useReCaptcha();

// Local reactive state
const containerShare = ref({});
const containerShareFound = ref(false);
const loading = ref(true);
const showCountdown = ref(false);
const showLogin = ref(false);
const showPin = ref(false);
const containerOwnerAvatar = ref("");
const containerOwner = ref({});
const hasErrors = ref(false);

const otpInput = ref("");

onMounted(async () => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Collection view langing page mounted");
  }

  const hmac = route.query.hmac;
  if (process.env.NODE_ENV === "development") {
    console.info("HMAC:", hmac);
  }
  const shareId = route.params.id;
  if (process.env.NODE_ENV === "development") {
    console.info("Share ID:", shareId);
  }

  if (process.env.NODE_ENV === "development") {
    setTimeout(function () {
      loadShare(shareId);
    }, 1000); // 1000 milliseconds = 1 second
  } else {
    loadShare(shareId);
  }
});

const loadShare = async (shareId) => {
  if (process.env.NODE_ENV === "development") {
    console.info("Loading share...");
  }

  getShare(shareId)
    .then((response) => {
      containerShare.value = response.data;
      containerShareFound.value = true;
      if (process.env.NODE_ENV === "development") {
        console.debug(
          "Share found, performing secutiry checks",
          containerShare.value,
        );
      }

      if (containerShare.value.allowAnonymousAccess) {
        if (process.env.NODE_ENV === "development") {
          console.debug("Anonymous access is allowed");
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          console.debug("Anonymous access is not allowed");
        }
      }

      // Is this share retired or disabled?
      if (!containerShare.value.enabled || containerShare.value.retired) {
        containerShareFound.value = false;
        return;
      }

      getContainerOwner(containerShare.value.container).then((response) => {
        containerOwner.value = response.data;
        if (process.env.NODE_ENV === "development") {
          console.debug("Container owner", containerOwner.value);
        }
      });

      getContainerOwnerAvatar(containerShare.value.container).then(
        (response) => {
          containerOwnerAvatar.value = response.data;
          if (process.env.NODE_ENV === "development") {
            console.debug("Container owner avatar", containerOwnerAvatar.value);
          }
        },
      );

      // Now do some checks to see if we need to show the countdown
      if (containerShare.value.sunriseDate !== null) {
        if (new Date(containerShare.value.sunriseDate) > new Date()) {
          showCountdown.value = true;
          // All done return
          return;
        }
      }

      if (containerShare.value.sunriseDate !== null) {
        if (new Date(containerShare.value.sunriseDate) < new Date()) {
          // We can't show an expired album
          containerShareFound.value = false;
          return;
        }
      }

      // Here we can show the link! Yay\o/

      // In any case show the login buttons
      showLogin.value = true;

      if (containerShare.value.pin.length === 0) {
        if (process.env.NODE_ENV === "development") {
          console.debug("No pin required");
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          console.info("Pin required Enabled the pin input");
        }
        showPin.value = true;
      }
    })
    .catch((error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Error fetching the share:", error);
      }
      throw error;
    })
    .finally(() => {
      loading.value = false;
    });
};

function checkPin(pin) {
  if (process.env.NODE_ENV === "development") {
    console.info("Checking pin...");
  }

  const result = pin === containerShare.value.pin;

  if (result) {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is correct");
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is incorrect");
    }
    global.$warn(t("collection-viewer-landing.invalid-pin-message"));

    otpInput.value = "";
  }

  return result;
}

function getDeadline() {
  return moment(String(containerShare.value.sunriseDate)).format(
    "YYYY-MM-DD HH:mm:ss",
  );
}

function anonumousLogin() {
  if (process.env.NODE_ENV === "development") {
    console.log("Anonymous login");
  }
  if (!showPin.value) {
    if (process.env.NODE_ENV === "development") {
      console.debug("No pin required, redirecting to collection viewer");
    }
    gotoViewer();
  } else {
    if (process.env.NODE_ENV === "development") {
      console.info("Pin is required to view this collection");
    }
  }

  if (showPin.value && checkPin(otpInput.value)) {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is correct, redirecting to collection viewer");
    }
    gotoViewer();
  } else {
    if (process.env.NODE_ENV === "development") {
      console.warn("Pin is incorrect");
    }
  }
}

async function googleLogin() {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("Google login");
    }

    const isPinValid = checkPin(otpInput.value);
    const isReCaptchaValid = await checkReCaptcha();

    if (!isReCaptchaValid) {
      global.$warn(t("collection-viewer-landing.human-verification-failed"));
      return;
    }

    // Note: the checkPin method will show a warning if the pin is incorrect
    if (showPin.value && isPinValid) {
      if (process.env.NODE_ENV === "development") {
        console.info("Pin is correct");
      }

      if (authStore.hasToken) {
        // Is registered so go ahead and redirect to the viewer. If not wait for the callback to redirect
        nextTick(() => {
          gotoViewer();
        });
      }
    } else if (showPin.value && !isPinValid) {
      if (process.env.NODE_ENV === "development") {
        console.debug("Pin is incorrect");
      }

      // Do nothing, just return, we show an error message in the checkPin method
      return;
    }

    // Here we're all set go ahead and redirect to the viewer
    gotoViewer();
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.error("Error during Google login", error);
    }
    global.$warn(
      t("collection-viewer-landing.an-error-occurred-during-google-login"),
    );
  }
}

async function loginCallback(value) {
  if (process.env.NODE_ENV === "development") {
    console.debug("Google login callback", value);
  }

  try {
    // Specify that the new user (if in the BE is a new user) will be a guest
    value.guest = true;

    await authStore.signInAsync(value);
  } catch (error) {
    if (error.isAxiosError && error.code === "ERR_NETWORK") {
      if (process.env.NODE_ENV === "development") {
        console.error("Network error occurred during sign-in:", error);
      }
      hasErrors.value = true;
    } else {
      if (process.env.NODE_ENV === "development") {
        console.error("An error occurred during sign-in:", error);
      }
      hasErrors.value = true;
    }
  }

  nextTick(() => {
    gotoViewer();
  });
}

async function checkReCaptcha() {
  // Esegui il reCAPTCHA e ottieni il token
  const token = await executeRecaptcha("submit_action");

  // Invia il token al backend per la verifica
  const response = await validateReCaptcha(token, authStore.user.name);

  const result = response.data.success;

  if (result) {
    if (process.env.NODE_ENV === "development") {
      console.debug("ReCaptcha verification successful");
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.debug("ReCaptcha verification failed");
    }
  }

  return result;
}

function loginError(value) {
  hasErrors.value = true;
  if (process.env.NODE_ENV === "development") {
    console.error("Login error", value);
  }
}

function gotoViewer() {
  // Redirect to collection viewer
  global.$router.push({
    name: "collectionviewer",
    params: { id: route.params.id },
    query: { hmac: route.query.hmac },
  });
}

const goBackToHomepage = () => {
  global.$router.push({ name: "home" });
};
</script>

<style scoped>
.login-card-light {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.login-card-dark {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(24, 24, 24, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}
</style>
